import globalStyles from './residential/global-styles'
import themeVars from './theme-vars'

const theme = {
  colors: {
    mint: { // defined to apply to form inputs
      100: '#63e0c9',
      200: '#63e0c9',
      300: '#63e0c9',
      400: '#63e0c9',
      500: '#63e0c9',
      600: '#63e0c9',
      700: '#63e0c9',
      800: '#63e0c9',
      900: '#63e0c9',
    }
  },
  fonts: {
    body: 'canada-type-gibson, sans-serif',
    heading: 'montserrat, sans-serif',
    mono: 'monospace'
  },
  fontSizes: {
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '18px',
    xl: '20px'
  },
  fontWeights: {
    normal: 200,
    medium: 200,
    bold: 600
  },
  textStyles: {
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: 'montserrat, sans-serif',
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '10px',
        '&:focus': {
          boxShadow: 'none'
        }
      },
      variants: {
        themeDefault: {
          background: themeVars.gradients.blueMint,
        },
        purpleBlue: {
          background: themeVars.gradients.bluePurple,
        },
        purpleOrange: {
          background: themeVars.gradients.purpleOrange,
        },
        blueMint: {
          background: themeVars.gradients.blueMint,
        },
        blueSlate: {
          background: themeVars.gradients.blueSlate,
        },
        white: {
          background: themeVars.colors.white,
        },
      },
      defaultProps: {
        variant: 'blueMint',
      }
    },
    Heading: {
      variants: {
        kicker: {
          fontSize: '20px!important',
          color: themeVars.colors.mint,
          fontWeight: '800!important',
          textTransform: 'uppercase',
          marginBottom: '10px!important'
        }
      }
    }
  },
  breakpoints: ["40em", "64em", "75em", "90em"], // 640px, 1024px, 1200px, 1440px
  ...globalStyles
}

export default theme