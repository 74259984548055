import themeVars, { tracking, remCalc, hexToRGB } from '../theme-vars'
import headerStyles from './header'
import footerStyles from './footer'
import wordpressStyles from './wordpress'

const globalStyles = {
  styles: {
    global: {
      '*': {
        borderColor: '#95b9e4'
      },
      '*::placeholder': {
        color: '#95b9e4'
      },
      body: {
        color: themeVars.colors.blue,
      },
      html: {
        fontFamily: themeVars.bodyFontFamily,
        letterSpacing: tracking(30),
        lineHeight: 1.444,
        fontSize: '1rem',
        '@media (min-width: 640px)': {
          fontSize: '18px',
        }
      },
      'sup': {
        fontSize: '58%',
        top: '-0.5em',
        '> sup': {
          opacity: 0.5
        }
      },
      'table td, table th': {
        padding: '0.5em'
      },
      // Override Chakra's defaults so we don't have to use textStyle props
      '#___gatsby, * body': {
        'h1, h2, h3, h4, h5, h6': {
          fontFamily: themeVars.headerFontFamily,
          fontWeight: themeVars.headerFontWeight,
          lineHeight: 'normal',
          'strong, b': {
            fontWeight: themeVars.headerFontWeightStrong,
          }
        },
        h1: {
          fontSize: remCalc(42),
          marginBottom: remCalc(36),
          '@media (min-width: 640px)': {
            fontSize: remCalc(60),
          },
        },
        h2: {
          fontSize: remCalc(35),
          marginBottom: remCalc(30),
          '@media (min-width: 640px)': {
            fontSize: remCalc(50),
          },
        },
        h3: {
          fontSize: remCalc(29),
          marginBottom: remCalc(30),
          '@media (min-width: 640px)': {
            fontSize: remCalc(42),
          },
        },
        h4: {
          fontSize: remCalc(24),
          marginBottom: remCalc(26),
          '@media (min-width: 640px)': {
            fontSize: remCalc(34),
          },
        },
        h5: {
          fontSize: remCalc(22),
          marginBottom: remCalc(18),
          '@media (min-width: 640px)': {
            fontSize: remCalc(26),
          },
        },
        h6: {
          fontSize: remCalc(18),
          marginBottom: remCalc(18),
          '@media (min-width: 640px)': {
            fontSize: remCalc(22),
          },
        },
      },
      h1: {
        fontSize: remCalc(42),
        marginBottom: remCalc(36),
        '@media (min-width: 640px)': {
          fontSize: remCalc(60),
        },
      },
      h2: {
        fontSize: remCalc(35),
        marginBottom: remCalc(30),
        '@media (min-width: 640px)': {
          fontSize: remCalc(50),
        },
      },
      h3: {
        fontSize: remCalc(29),
        marginBottom: remCalc(30),
        '@media (min-width: 640px)': {
          fontSize: remCalc(42),
        },
      },
      h4: {
        fontSize: remCalc(24),
        marginBottom: remCalc(26),
        '@media (min-width: 640px)': {
          fontSize: remCalc(34),
        },
      },
      h5: {
        fontSize: remCalc(22),
        marginBottom: remCalc(18),
        '@media (min-width: 640px)': {
          fontSize: remCalc(26),
        },
      },
      h6: {
        fontSize: remCalc(18),
        marginBottom: remCalc(18),
        '@media (min-width: 640px)': {
          fontSize: remCalc(22),
        },
      },
      p: {
        fontWeight: 200,
        marginBottom: remCalc(40),
      },
      b: {
        fontWeight: 600,
      },
      strong: {
        fontWeight: 800,
      },
      a: {
        color: themeVars.colors.purple,
        transition: 'color 0.2s ease-in-out, opacity 0.2s ease-in-out',
        '&:not(.button, .wp-block-button__link):hover': {
          color: themeVars.colors.mint
        },
        '&.onBG': {
          color: themeVars.colors.white,
          opacity: 0.8,
          '&:not(.button, .wp-block-button__link):hover': {
            opacity: 1
          }
        },
        '&:hover': {
          color: themeVars.colors.mint
        }
      },
      ul: {
        li: {
          lineHeight: 'normal'
        }
      },
      '.container': { // Our page container
        width: '100%',
        maxWidth: themeVars.bp.maxWidth,
        margin: '0 auto',
        paddingLeft: remCalc(30),
        paddingRight: remCalc(30),
        /* '@media (min-width: 640px)': {
          paddingLeft: remCalc(15),
          paddingRight: remCalc(15),
        }, */
        '&.align-wide': {
          maxWidth: '100%',
        },
        '&.align-full': {
          maxWidth: '100%',
          paddingLeft: 0,
          paddingRight: 0,
        },
        '&.mW1200': {
          maxWidth: '1200px'
        },
        '&.mW1024': {
          maxWidth: '1024px'
        },
        '&.mW960': {
          maxWidth: '960px'
        },
        '&.mW640': {
          maxWidth: '640px'
        },
      },
      '.button': { // Buttons (default blue to purple)
        display: 'inline-block',
        borderRadius: '10px',
        padding: '8px 42px!important',
        marginBottom: '20px',
        fontWeight: 700,
        color: themeVars.colors.white,
        background: themeVars.gradients.bluePurple,
        transition: `transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out`,
        cursor: 'pointer',
        whiteSpace: 'normal',
        height: 'auto',
        '&:hover': {
          color: themeVars.colors.white,
          transform: 'translateY(-2px)',
          boxShadow: `0px 6px 0 ${hexToRGB(themeVars.colors.mint, 0.6)}`,
        },
        '&[disabled]:hover': {
          backgroundImage: `linear-gradient(to right, ${themeVars.colors.blue}, ${themeVars.colors.mint})!important`,
        },
        '&:focus': {
          transform: 'translateY(1px)',
          boxShadow: 'none'
        },
        '&.buttonChecked': {
          backgroundImage: 'none',
          background: 'none!important',
          color: themeVars.colors.blue,
          border: `3px solid ${themeVars.colors.blue}`,
          position: 'relative',
          '&::after': {
            content: '"✓"',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '25px',
            height: '25px',
            fontWeight: 'bold',
          }
        },
        '&.buttonWhite': {
          background: themeVars.colors.white,
          color: themeVars.colors.purple,
          '&:hover': {
            color: themeVars.colors.purple,
            boxShadow: `0px 6px 0 ${hexToRGB(themeVars.colors.purple, 0.9)}`,
          },
          '&:focus': {
            transform: 'translateY(1px)',
            boxShadow: 'none'
          }
        },
        '&.buttonBlue': {
          background: themeVars.colors.blue
        },
        '&.buttonBlueMint': {
          background: themeVars.gradients.blueMint,
          '&:hover': {
            boxShadow: `0px 6px 0 ${hexToRGB(themeVars.colors.mint, 0.9)}`,
          },
          '&:focus': {
            transform: 'translateY(1px)',
            boxShadow: 'none'
          }
        },
        '&.buttonPurpleOrange': {
          background: themeVars.gradients.purpleOrange,
          '&:hover': {
            boxShadow: `0px 6px 0 ${hexToRGB(themeVars.colors.orange, 0.9)}`,
          },
          '&:focus': {
            transform: 'translateY(1px)',
            boxShadow: 'none'
          }
        },
        '&.buttonBlueSlate': {
          background: themeVars.gradients.blueSlate,
          '&:hover': {
            boxShadow: `0px 6px 0 ${hexToRGB(themeVars.colors.blue, 0.9)}`,
          },
          '&:focus': {
            transform: 'translateY(1px)',
            boxShadow: 'none'
          }
        }
      },
      '.sliderBlock': {
        '.slick-next, .slick-prev' : {
          width: '24px',
          height: '90px',
          background: `linear-gradient(to right, #183a62, ${themeVars.colors.blue}, ${themeVars.colors.blue})`,
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          //transition: 'width 0.3s ease-in-out',
          '&::before': {
            content: '""',
            position: 'absolute',
            color: themeVars.colors.white,
            backgroundImage: 'url(/images/slickArrow.svg)',
            backgroundSize: '60%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
            top: 0,
            right: 0,
          },
          '&:hover, &:focus': {
            background: `linear-gradient(to right, #183a62, ${themeVars.colors.blue}, ${themeVars.colors.blue})`,
            width: '32px',
            '&::before': {
              width: '32px'
            }
          }
        },
        '.slick-prev': {
          left: '-4px',
          zIndex: '10',
          transformOrigin: '50% 25%',
          transform: 'rotate(-180deg)',
          '&:hover, &:focus': {
            left: '-12px'
          }
        },
        '.slick-next': {
          right: '-4px',
          '&:hover, &:focus': {
            right: '-12px'
          }
        },
      },
      '.slick-dots': { // Slick slider dots
        'li': {
          'button::before': {
            width: '12px',
            height: '12px',
            content: '""',
            opacity: 1,
            backgroundColor: themeVars.colors.white,
            borderRadius: '50%',
            border: `3px solid ${themeVars.colors.blue}`,
          },
          '&.slick-active': {
            'button::before': {
              opacity: 1,
              backgroundColor: themeVars.colors.blue,
            }
          }
        }
      },
      '.cardBox': {
        display: 'flex',
        borderRadius: themeVars.borderRadius,
        boxShadow: themeVars.boxShadow,
        color: themeVars.colors.white,
        overflow: 'hidden',
        //@media flexDirection: column/row
        '.cardDescription': {
          flex: "2 1",
          padding: '30px',
          background: themeVars.gradients.bluePurple,
          '.cardDetails': {
            display: 'flex',
            marginBottom: '30px',
            //@media flexDirection: column/row
            'span:first-of-type': {
              display: 'inline-block',
              marginRight: '30px',
            }
          }
        },
        '.wufoo': {
          'input.text': {
            outline: '0px',
            position: 'relative',
            appearance: 'none',
            transition: 'all 0.2s ease 0s',
            fontSize: '18px',
            paddingLeft: '1rem',
            paddingrRight: '1rem',
            borderRadius: '0.375rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderImage: 'initial',
            background: 'inherit',
            borderColor: 'rgb(226, 232, 240)',
          }
        },
        '.cardAside': {
          display: 'flex',
          flex: '1 1',
          padding: '30px',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: themeVars.colors.blue
        }
      },
      '.chakra-modal__header': {
        a: {
          color: themeVars.colors.white,
          '&:hover': {
            opacity: 1
          }
        }
      },
      '.themeGradient': {
        background: themeVars.gradients.bluePurple
      },
      '.themeGradient2': {
        background: themeVars.gradients.blueMint
      },
      // wysiwyg inline-images event fix class
      '.noStaticWidth': {
        width: '100%!important'
      },
      'iframe.iframe-responsive': {
        width: '100%',
        height: '100vh',
      },
      ...headerStyles,
      ...footerStyles,
      ...wordpressStyles,
    } // global
  }
}

export default globalStyles