import React, { useEffect } from 'react'
// import { graphql } from 'gatsby'
import {
  ChakraProvider,
  extendTheme,
  Heading,
  Box,
  Flex
} from '@chakra-ui/react';
import Layout from '../components/Layout'
import pageTheme from '../gatsby-plugin-chakra-ui/theme'
import SEO from '../components/SEO'
import Slider from 'react-slick'
import '../assets/slick/slick.scss'
import useGetPlansByID from '../utils/useGetPlansByID'
import useGetAddonsByID from '../utils/useGetAddonsByID'
import ModalPlansInternet from '../components/ModalPlansInternet'
import themeVars from '../gatsby-plugin-chakra-ui/theme-vars'

const theme = extendTheme({ ...pageTheme })

const PageInternet = () => {
  
  const plans_relationships = [
    '117348',
    '117353',
    '117354',
    '117355',
    '117356',
  ]
  const plans = useGetPlansByID(plans_relationships)
  
  const addons_relationship = [
    '117319',
    '117326',
    '117330',
    '117331',
  ]
  const addons = useGetAddonsByID(addons_relationship)

  // Slider settings
  const sliderSettings = {
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3.25,
    speed: 100,
    variableWidth: false,
    adaptiveHeight: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2.1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.05
        }
      },
    ]
  }

  return (
    <ChakraProvider theme={theme}>
        <Layout className="testingsteve" heroContent={{
            heroSlides: [{
                heroSlideIllustrationSelect: 'careers',
                heroSlideContentH1: '<strong>Unlimited Data</strong>, Unlimited Possibilities',
                heroSlideContentH4: 'Endless browsing, streaming, and gaming. No gimmicks. No throttling. No hidden fees.',
                heroSlideLink: null,
                secondaryButton: null,
                teritaryButton: null,
            }],
        }}>
            <SEO title="Internet" noIndex />
            <Box mt="300px" mb="150px" className="container" maxWidth="1440px">
              <Heading as="h2">
                It's internet, <strong>but easier.</strong>
              </Heading>
            </Box>
            <Box backgroundColor="#ecf8fd" py="100px">
              <Box
                className="container"
                maxWidth="1440px"
              >
                <Heading as="h6" variant="kicker">MTA Internet Plans</Heading>
                <Heading as="h2">Pick your internet plan to get started</Heading>
              </Box>
              <Box
                marginLeft={[null, null, null, 'calc((100% - 1440px) / 2)']}
                paddingLeft="15px"
                sx={{
                  '.inetCard': {
                    padding: '10px 12px 20px 0',
                    '.inetCardInner': {
                      padding: '28px',
                      borderRadius: '20px',
                      backgroundColor: 'white',
                      boxShadow: '6px 6px 16px rgba(0, 0, 0, 0.2)'
                    }
                  },
                  '.slick-slider': {
                    '.slick-prev, .slick-next': {
                      width: '40px',
                      height: '40px',
                      boxShadow: '0 5px 12px rgba(0, 0, 0, 0.25)',
                      borderRadius: '999px',
                      '&:before, &:after': {
                        color: themeVars.colors.mint,
                        opacity: 1
                      },
                      '&.slick-disabled': {
                        opacity: 0,
                        pointerEvents: 'none'
                      }
                    },
                    '.slick-prev': {
                      left: '-8px',
                      zIndex: 10,
                      '&::before': {
                        content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 80 80'%3E%3Ccircle cx='40' cy='40' r='24.78' fill='%23fff' stroke-width='0'/%3E%3Cpath d='M40,0c22.09,0,40,17.91,40,40s-17.91,40-40,40S0,62.09,0,40,17.91,0,40,0ZM42.82,55.3c.48.47,1.11.7,1.73.7.66,0,1.31-.26,1.8-.76.96-.99.93-2.58-.06-3.54l-12.13-11.7,12.13-11.7c.99-.96,1.02-2.54.06-3.54-.96-.99-2.54-1.02-3.54-.06l-15.87,15.3,15.87,15.3Z' fill='${themeVars.colors.mint.replace('#', '%23')}' stroke-width='0'/%3E%3C/svg%3E")`
                      }
                    },
                    '.slick-next': {
                      right: '10px',
                      '&::before': {
                        content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 80 80'%3E%3Ccircle cx='40' cy='40' r='24.78' fill='%23fff' stroke-width='0'/%3E%3Cpath d='M40,0C17.91,0,0,17.91,0,40s17.91,40,40,40,40-17.91,40-40S62.09,0,40,0ZM37.18,55.3c-.48.47-1.11.7-1.73.7-.66,0-1.31-.26-1.8-.76-.96-.99-.93-2.58.06-3.54l12.13-11.7-12.13-11.7c-.99-.96-1.02-2.54-.06-3.54.96-.99,2.54-1.02,3.54-.06l15.87,15.3-15.87,15.3Z' fill='${themeVars.colors.mint.replace('#', '%23')}' stroke-width='0'/%3E%3C/svg%3E")`
                      }
                    },
                    '.slick-list': {
                      '.slick-track': {
                        display: 'flex',
                        '.slick-slide': {
                          height: 'inherit',
                          '> div': {
                            height: '100%',
                            '.inetCard': {
                              height: '100%'
                            }
                          }
                        }
                      }
                    }
                  },
                  '@media only screen and (min-width: 640px)': {
                    '.inetCard': {
                      padding: '10px 24px 20px 0',
                    },
                    '.slick-slider': {
                      '.slick-prev, .slick-next': {
                        width: '60px',
                        height: '60px'
                      },
                      '.slick-prev': {
                        left: 0
                      },
                      '.slick-next': {
                        right: '20px'
                      }
                    }
                  }
                }}
              >
                <Slider {...sliderSettings}>
                  {plans.map((p, i) => {
                    const price = p.planOptions[0].ptplanPrice.split('.')
                    return (
                    <Box key={`inet-card-key-${i}`} className="inetCard">
                      <Flex className="inetCardInner" height="100%" flexDirection="column" justifyContent="space-between">
                        <Box>
                          <Heading as="h6" variant="kicker" color={themeVars.colors.blue}>{p.planOptions[0].ptplanName}</Heading>
                          <Heading as="h2" sx={{ sup: {fontSize: '58%'}}}>
                            <strong>${price[0]}</strong>
                            <sup><strong>{price[1]}</strong><sup style={{opacity: '0.4'}}> /mo</sup></sup>
                          </Heading>
                          <Box color={themeVars.colors.gray} dangerouslySetInnerHTML={{ __html: p.planDescription}} />
                          <Box my="28px">
                            <Flex gridGap="10px" alignItems="center">
                              <Box as="img" src={p.planDetail01Icon} maxW="50px" w="full" />
                              <Box fontWeight="bold" fontFamily={themeVars.headerFontFamily}>{p.planDetail01}</Box>
                            </Flex>
                            <Flex gridGap="10px" alignItems="center">
                              <Box as="img" src={p.planDetail02Icon} maxW="50px" w="full" />
                              <Box fontFamily={themeVars.headerFontFamily}>{p.planDetail02}</Box>
                            </Flex>
                          </Box>
                        </Box>
                        <ModalPlansInternet plans={plans} addons={addons} defaultIndex={i} />
                      </Flex>
                    </Box>
                    )
                  })}
                </Slider>
              </Box>
            </Box>
        </Layout>
    </ChakraProvider>
  )
}

// export const query = graphql`
//   query internetPage {
//     plans: allWpPlan(sort: {fields: plan___ptplanOrder}) {
//       nodes {
//         databaseId
//         plan {
//           ptplanOptions {
//             ptplanName
//             ptplanSlug
//             ptplanPrice
//           }
//         }
//       }
//     }

//     addons: allWpPlanAddon(sort: {fields: addon___ptaddonOrder}) {
//       nodes {
//         databaseId
//         title
//         addon {
//           ptaddonPrice
//         }
//       }
//     }
//   }
// `

export default PageInternet